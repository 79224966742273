@use '../functions/util-functions' as fn;
@use 'sass:color';

/// Hover pseudo classes
///
/// @param $color - colour of link to lighten
@mixin hover($color) {
  &:hover,
  &:active {
    outline: 0;
    color: color.adjust($color, $lightness: -10%);
  }

  &:focus {
    outline: thin dotted;
    color: color.adjust($color, $lightness: -10%);
  }
}

@mixin link($link-color: #0076be) {
  .text-link {
    @include link-base($link-color);
  }
}

@mixin alternate-link($link-color) {
  .alternate-text-link {
    @include link-base($link-color);

    &::after {
      display: inline-block;
      content: '>';
      text-decoration: none;
      margin-left: fn.rem(3);
      font-size: inherit;
    }
  }
}

@mixin link-base($link-color) {
  font-size: inherit;
  line-height: inherit;
  color: $link-color;
  text-decoration: underline;
  transition: all 200ms ease;
  cursor: pointer;

  @include hover($link-color);
}

@mixin message-box($severity) {
  $color: inherit;
  $border-color: unset;
  $background-color: unset;

  @if $severity == 'error' {
    $color: #c00;
    $border-color: #c00;
    $background-color: #fbc4c8;
  } @else if $severity == 'warning' {
    $border-color: #fec433;
    $background-color: #fef2d6;
  } @else if $severity == 'info' {
    $color: #003652;
    $border-color: #3e9cd0;
    $background-color: #e6f2f9;
  }

  display: flex;
  align-items: center;
  color: $color;
  padding: fn.rem(15) fn.rem(20);
  border-radius: 5px;
  border: solid 1px $border-color;
  background-color: $background-color;
  margin: fn.rem(15) 0;
}

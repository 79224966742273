// Has full values available at the time from Dhwani's Figma [cas@29/06/2023]
// https://www.figma.com/file/rWBCgdm2F9WLz835GKIp4P/QN---Design-Guidelines-%2B-Component-Library?node-id=240%3A1643&mode=dev

/*
Material    | HTML Element
--------------------------
headline-1	| None
headline-2	| None
headline-3	| None
headline-4	| None
headline-5	| <h1>
headline-6	| <h2>
subtitle-1	| <h3>
body-1	    | <h4>
None    	| <h5>
None    	| <h6>
body-2	    | Body text
subtitle-2	| None
caption	    | None

Therefore:
  $headline-1 = $headline-5
  $headline-2 = $headline-6
  $headline-3 = $subtitle-1 & $headline-4
*/

@use '@angular/material' as mat;
@use './styles/variables' as var;

$font-family: proxima-nova, calibri, sans-serif;
$ag-typography: mat.define-typography-config(
  $font-family: $font-family,
  $headline-1:
    mat.define-typography-level(
      $font-family: $font-family,
      $font-weight: normal,
      $font-size: 26px,
      $line-height: normal,
      $letter-spacing: normal,
    ),
  $headline-2:
    mat.define-typography-level(
      $font-family: $font-family,
      $font-weight: normal,
      $font-size: 20px,
      $line-height: normal,
      $letter-spacing: normal,
    ),
  $headline-3:
    mat.define-typography-level(
      $font-family: $font-family,
      $font-weight: normal,
      $font-size: 18px,
      $line-height: normal,
      $letter-spacing: normal,
    ),
  $headline-4:
    mat.define-typography-level(
      $font-family: $font-family,
      $font-weight: normal,
      $font-size: 18px,
      $line-height: normal,
      $letter-spacing: normal,
    ),
  $headline-5:
    mat.define-typography-level(
      $font-family: $font-family,
      $font-weight: normal,
      $font-size: 26px,
      $line-height: normal,
      $letter-spacing: normal,
    ),
  $headline-6:
    mat.define-typography-level(
      $font-family: $font-family,
      $font-weight: normal,
      $font-size: 20px,
      $line-height: normal,
      $letter-spacing: normal,
    ),
  $subtitle-1:
    mat.define-typography-level(
      $font-family: $font-family,
      $font-weight: normal,
      $font-size: 18px,
      $line-height: normal,
      $letter-spacing: normal,
    ),
  $body-1:
    mat.define-typography-level(
      $font-family: $font-family,
      $font-weight: normal,
      $font-size: 16px,
      $line-height: normal,
      $letter-spacing: normal,
    ),
  $body-2:
    mat.define-typography-level(
      $font-family: $font-family,
      $font-weight: normal,
      $font-size: 14px,
      $line-height: normal,
      $letter-spacing: normal,
    ),
  $button:
    mat.define-typography-level(
      $font-family: $font-family,
      $font-weight: normal,
      $font-size: 14px,
      $line-height: normal,
      $letter-spacing: 1.25%,
    ),
);

@mixin mat-typography($selector: '.mat-typography') {
  @include mat.typography-hierarchy($ag-typography, $selector);
}

// These are values that are missing from Figma.
// Not sure if we 100% need these? [cas@30/06/2023]
// $subtitle-2:
//   mat.define-typography-level(
//     $font-family: $font-family,
//     $font-weight: normal,
//     $font-size: 1rem,
//     $line-height: normal,
//     $letter-spacing: normal,
//   ),
// $caption:
//   mat.define-typography-level(
//     $font-family: $font-family,
//     $font-weight: normal,
//     $font-size: 1rem,
//     $line-height: normal,
//     $letter-spacing: normal,
//   ),
// $overline:
//   mat.define-typography-level(
//     $font-family: $font-family,
//     $font-weight: normal,
//     $font-size: 1rem,
//     $line-height: normal,
//     $letter-spacing: normal,
//   ),

@mixin typography() {
  h1 {
    font-family: $font-family;
    font-size: 26px;
    font-weight: normal;
    letter-spacing: normal;
  }

  h2 {
    font-family: $font-family;
    font-size: 20px;
    font-weight: normal;
    letter-spacing: normal;
  }

  h3 {
    font-family: $font-family;
    font-size: 18px;
    font-weight: normal;
    letter-spacing: normal;
  }

  h4 {
    font-family: $font-family;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: normal;
  }

  html {
    font-family: $font-family;
    font-size: var.$font-size-base;
    letter-spacing: normal;
  }
}

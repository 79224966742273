@use '../mixins';
@use '../variables' as vars;
@use '../functions' as fn;
@use '@angular/material' as mat;

@mixin theme($theme-map: null, $mode: 'light') {
  $theme: fn.get-theme($theme-map, $mode);

  @include links($theme);
  @include mat.core;
  @include mat.core-theme($theme);
}

@mixin theme-with-all-component-themes($theme-map: null, $mode: 'light') {
  $theme: fn.get-theme($theme-map, $mode);

  @include links($theme);
  @include mat.core;
  @include mat.core-theme($theme);
  @include mat.all-component-themes($theme);
}

@mixin links($theme) {
  @include mixins.link(fn.get-theme-color($theme, primary, 200));
  @include mixins.alternate-link(fn.get-theme-color($theme, primary, 500));
}

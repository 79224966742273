$font-size-base: 14px;
$grid-breakpoints: (
  xxxs: 320px,
  xxs: 370px,
  xs: 480px,
  sm: 601px,
  md: 767px,
  lg: 969px,
  xl: 1200px,
);
$font-weight-thin: 100;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-ultra: 900;
$z-index-breakpoints: (
  sticky-top-panel: 5,
  scroll-to-top-button: 5,
  backdrop: 10,
  ui-header: 10,
  clp-filter: 12,
  dialog-close-icon: 500,
  toast-container: 1010,
  progress-spinner: 1100,
  header-mobile-nav: 500,
  header-mobile-nav-show: 500,
);

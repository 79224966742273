@use 'utilities' as utils;

// TODO: change the color code to use palettes [pr@30/08/2023]
/// General Form Fields
.mat-mdc-form-field {
  --mdc-outlined-text-field-hover-outline-color: #c94c88;

  .mat-mdc-text-field-wrapper {
    background-color: #fff;
  }

  .mat-mdc-form-field-subscript-wrapper {
    font-size: utils.rem(12);
    font-weight: utils.$font-weight-normal;
    letter-spacing: normal;
    line-height: 13px;
  }

  // stylelint-disable selector-class-pattern
  .mdc-text-field--no-label:not(.mdc-text-field--textarea),
  .mat-mdc-form-field-input-control.mdc-text-field__input,
  .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
    vertical-align: middle;
  }

  .mdc-text-field {
    $parent: &;

    .mdc-notched-outline {
      &__notch {
        // form-field label size
        .mat-mdc-floating-label {
          font-size: utils.rem(14);
        }
      }
    }

    // form-field border disabled
    &--disabled {
      background-color: #f4f4f4;

      .mdc-notched-outline {
        &__leading,
        &__notch,
        &__trailing {
          border-color: #ccc;
        }
      }
    }

    // form-field border focused
    &--focused:not(#{$parent}--disabled, #{$parent}--invalid) {
      .mdc-notched-outline {
        &__leading,
        &__notch,
        &__trailing {
          border-width: 1px;
          border-color: utils.get-palette-color(
            utils.$ag-light-accent-palette,
            500
          );
        }

        .mat-mdc-floating-label {
          color: utils.get-palette-color(utils.$ag-light-accent-palette, 500);
        }
      }
    }

    // form-field border invalid
    &--invalid:not(#{$parent}--disabled) {
      .mdc-notched-outline {
        &__leading,
        &__notch,
        &__trailing {
          border-width: 1px;
          border-color: utils.get-palette-color(
            utils.$ag-light-warn-palette,
            500
          );
        }

        .mat-mdc-floating-label {
          color: utils.get-palette-color(utils.$ag-light-warn-palette, 500);
        }
      }
    }

    // form-field border default
    &--outlined:not(
        #{$parent}--disabled,
        #{$parent}--invalid,
        #{$parent}--focused
      ) {
      .mdc-notched-outline {
        &__leading,
        &__notch,
        &__trailing {
          border-color: #ccc;
        }
      }

      // form-field border hover
      &:hover {
        .mdc-notched-outline {
          &__leading,
          &__notch,
          &__trailing {
            border-color: utils.get-palette-color(
              utils.$ag-light-accent-palette,
              500
            );
          }
        }
      }
    }
  }
}

.mdc-menu-surface {
  background-color: #fff !important; // TODO: needs investigation [pr@30/08/2023]
}

.ui-dialog-container {
  min-width: 300px !important;

  @include utils.media-breakpoint-up(md) {
    max-width: 900px !important;
  }
}

// Mat button
.mat-mdc-icon-button {
  --mdc-icon-button-disabled-icon-color: rgb(0 0 0 / 38%) !important;
}

// mat-slide-toggle starts
:root {
  --mdc-switch-track-height: 32px;
  --mdc-switch-track-width: 52px;
  --mdc-switch-track-shape: 50px;
  --mdc-switch-handle-width: 25px;
  --mdc-switch-handle-height: 25px;
  --mdc-switch-handle-shape: 50px;
}

.mdc-switch__handle-track {
  left: 6px !important;
}

.mdc-switch--selected .mdc-switch__handle-track {
  transform: translateX(calc(100% - 12px)) !important;
}

.mdc-switch:enabled .mdc-switch__track::after {
  --mdc-switch-selected-track-color: #c94c88;
  --mdc-switch-selected-focus-track-color: #e15b9b;
  --mdc-switch-selected-hover-track-color: #e15b9b;
  --mdc-switch-selected-pressed-track-color: #e15b9b;
}

.mdc-switch:enabled .mdc-switch__track::before {
  --mdc-switch-unselected-track-color: #a1a198;
  --mdc-switch-unselected-focus-track-color: #acaca3;
  --mdc-switch-unselected-hover-track-color: #acaca3;
  --mdc-switch-unselected-pressed-track-color: #acaca3;
}

.mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after,
.mdc-switch.mdc-switch--unselected:enabled .mdc-switch__handle::after {
  --mdc-switch-unselected-handle-color: #fff;
  --mdc-switch-unselected-hover-handle-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #fff;
  --mdc-switch-selected-handle-color: #fff;
  --mdc-switch-selected-hover-handle-color: #fff;
  --mdc-switch-selected-pressed-handle-color: #fff;
  --mdc-switch-selected-focus-handle-color: #fff;
}

// mat-slide-toggle ends

// mat-paginator
mat-paginator div.mat-mdc-paginator-range-label {
  margin: 0 0 0 4px;
}

// mat-paginator ends

// mat-dialog
.mat-mdc-dialog-container .mdc-dialog__content {
  font-size: 1rem !important;
  line-height: utils.rem(21) !important;
}

// mat-dialog ends

// material symbols
.material-symbols-outlined {
  &.filled {
    font-variation-settings: 'FILL' 1;
  }
}

// material symbols ends

// material accordion

.mat-expansion-panel {
  box-shadow: none !important;

  --mat-expansion-container-background-color: #fff;
  --mat-expansion-header-indicator-color: utils.get-theme-color(
    utils.$ag-theme,
    primary,
    500
  );
}

mat-panel-title,
.mat-expansion-panel {
  color: utils.get-theme-color(utils.$ag-theme, primary, 500) !important;
}

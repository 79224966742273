@use '../functions' as fn;
@use '../variables' as vars;
@use 'sass:color';

/// Media of at least the minimum breakpoint width.
///
/// @param {xs | sm | md | lg | xl} $name - minimum breakpoint width
/// @param $breakpoints {map} $breakpoints - containing minimum breakpoint width
/// @content apply for given breakpoint and wider.
///
/// @example
///   media-breakpoint-up(md){ @content };
///   // @media (min-width: 768px)
///   // .parent-content-class {
///   //    @content;
///   // }
@mixin media-breakpoint-up($name, $breakpoints: vars.$grid-breakpoints) {
  $min: fn.breakpoint-min($name, $breakpoints);

  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

/// Media of at least the maximum breakpoint width.
///
/// @param {xs | sm | md | lg | xl} $name - maximum breakpoint width
/// @param $breakpoints {map} $breakpoints - containing maximum breakpoint width
/// @content apply for given breakpoint and narrower.
///
/// @example
///   media-breakpoint-down(md){ @content };
///   // @media (max-width: 768px)
///   // .parent-content-class {
///   //    @content;
///   // }
@mixin media-breakpoint-down($name, $breakpoints: vars.$grid-breakpoints) {
  $max: fn.breakpoint-max($name, $breakpoints);

  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin styled-scrollbar {
  ::-webkit-scrollbar {
    width: fn.rem(8);
    height: fn.rem(8);
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #f6f6f6; // TODO: replace with palette colors
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #ddd; // TODO: replace with palette colors
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #ddd; // TODO: replace with palette colors
  }
}

@use 'utilities' as utils;

:root {
  --layout-wrapper-max-width: 1440px;
}

.font-bold {
  font-weight: utils.$font-weight-bold;
}

.main-content {
  padding: utils.rem(15);

  @include utils.media-breakpoint-up(md) {
    padding: utils.rem(20);
  }
}

@include utils.media-breakpoint-up(md) {
  .sticky-top-panel {
    position: sticky;
    top: 0;
    z-index: utils.get-z-index-breakpoint(sticky-top-panel);
    padding: utils.rem(20);
  }
}

.highlight {
  background-color: rgb(201 76 136 / 30%);
}

.overflow-hidden {
  overflow: hidden;
}

.content-spacing {
  margin-bottom: utils.rem(20);
}
